/**
 * miniAt
 * 
 * Call MINI.at JavaScript API via postMessage 
 * 
 * @see \\WEB\Resources\Doku\MINI\Online\MiniDigital-iframeComponent-180416-1058-92.pdf
 * @author jjarolim, adwerba
 */

window.miniAt = {
	
	debug: process.env.NODE_ENV === 'development',
	domain: 'https://www.mini.at',

	bootstrap: function() {
		
		// Listen to messages from parent
		
		window.addEventListener('message', function(event) {
			if (miniAt.debug) {
				console.log('callback triggered', event.data);
			}
		});
		
	},
	
	onBodyResize: debounce(function() {
		if( miniAt.debug ) { console.log('onBodyResize callback triggered'); }
		miniAt.iframeResize();
	}, 500),
	
	iframeResize: function() {

		if( miniAt.debug ) { console.log('iFrameResize called'); }
		
		var height = document.body.clientHeight;

		var data = {
			childOrigin: 'init',
			actions: [
				{
					action: 'resize',
					params: {
						height: height
					}
				}
			],
			callback: true
		};

		var message = JSON.stringify(data);

		if (window.parent) {
			if (miniAt.debug) {
				console.log('sendig to parent frame', message);
			}
			window.parent.postMessage(message, miniAt.domain);
		}
		
		
		
	}
	
}

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 * @see https://davidwalsh.name/essential-javascript-functions
 * @param {type} func
 * @param {type} wait
 * @param {type} immediate
 * @returns {Function}
 */
function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};
